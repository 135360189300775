// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-student-profile-form-js": () => import("./../../../src/pages/student-profile-form.js" /* webpackChunkName: "component---src-pages-student-profile-form-js" */),
  "component---src-templates-all-projects-js": () => import("./../../../src/templates/all-projects.js" /* webpackChunkName: "component---src-templates-all-projects-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-school-js": () => import("./../../../src/templates/school.js" /* webpackChunkName: "component---src-templates-school-js" */),
  "component---src-templates-student-js": () => import("./../../../src/templates/student.js" /* webpackChunkName: "component---src-templates-student-js" */)
}

